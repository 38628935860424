function nodeIdMask(nodeInput) {
    if (nodeInput.startsWith("lxd")) {
        return `主机${nodeInput.substring(3)}`;
    }
    return "error";
}

import {planing} from '@/common/constant'
import store from '../store/index'

function getPlaningStr(inputPlaning) {
    const instancePlaningMap = store.getters["instancePlaningMap"]
    return instancePlaningMap[inputPlaning]
}

function getPlaningStrList(allowPlannings) {
    return allowPlannings.map(x => getPlaningStr(x)).join(" ");
}

export {
    nodeIdMask,
    getPlaningStr,
    getPlaningStrList
}