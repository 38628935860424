<template>
  <div id="app">
    <router-view v-if="isRouteAlive"></router-view>
  </div>
</template>

<script>
import {useStore} from "vuex";

export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouteAlive: true
    }
  },
  mounted() {
    this.store = useStore();
    this.getInstancePlaning();
  },
  methods: {
    reload() {
      this.isRouteAlive = false;
      this.$nextTick(function () {
        this.isRouteAlive = true;
      })
    },
    getInstancePlaning() {
      this.$httpUtil.get('/dayu/api/v1/public-config/get-instance-planing-list', {}).then(res => {
        if (res) {
          const planingList = res.data;
          this.store.commit("setInstancePlaningList", planingList);
          let planingMap = {}
          planingList.forEach(planing => {
            planingMap[planing.specification.planing] = planing.name;
          });
          this.store.commit("setInstancePlaningMap", planingMap);
        }
      }, res => {
        console.log(res);
      }).finally(() => {
        //
      });
    },
  }
}
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  display: none;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#app {
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
  'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
}
</style>
