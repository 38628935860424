<template>
  <div id="container">
    <el-table :data="instances" style="width: 100%;min-height: 768px">
      <el-table-column
          label="实例主机信息">
        <template #default="scope">
          <el-tag class="ml-2" type="info">
            {{ scope.row.regionDisplayName }}
          </el-tag>
          <el-tag class="ml-2" type="primary" style="margin-left: 2px">
            {{ scope.row.locationNodeDisplayName }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column
          width="256px"
          label="实例名称"
          prop="name">
      </el-table-column>

      <el-table-column
          width="180px"
          label="创建时间"
          prop="createTime">
      </el-table-column>
      <el-table-column
          width="180px"
          label="更新时间"
          prop="updateTime">
      </el-table-column>

      <el-table-column
          label="实例状态"
          prop="statusStr">
        <template #default="scope">
          <el-tag class="ml-2" :type="getInstanceStatusTagColor(scope.row)">
            {{ scope.row.instanceStatusStr }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
          label="实例初始化状态"
          prop="cloudInitStatusStr">
        <template #default="scope">
          <el-tag class="ml-2" :type="getInstanceInitStatusTagColor(scope.row)">
            {{ scope.row.cloudInitStatusStr }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
          label="磁盘挂载状态"
          prop="volumeCheckStatusStr">
        <template #default="scope">
          <el-tag class="ml-2" :type="getInstanceVolumeStatusTagColor(scope.row)">
            {{ scope.row.volumeCheckStatusStr }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column align="right">
        <template #header>
          <el-button type="info" size="small" @click="getInstanceList(true)">刷新</el-button>
        </template>
        <template #default="scope">
          <el-button type="info" size="small" @click="handleRefreshState(scope.row)">刷新状态</el-button>
          <el-button type="primary" size="small" @click="handleGoDetails(scope.$index, scope.row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-button style="margin-top: 4px" type="primary" @click="handleGotoCreatePage">新建实例</el-button>
  </div>
</template>

<script>
import {
  commonSettings,
  instanceStatus,
  instanceCloudInitStatusStr,
  instanceCloudInitStatus,
  instanceVolumeStatus,
  serverDomain
} from '@/common/constant'
import {getPlaningStr} from '@/common/format'
import {useStore} from "vuex";
import Throttle from "@/common/throttle";
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import {LineChart} from 'echarts/charts';
import {UniversalTransition} from 'echarts/features';
import {CanvasRenderer} from 'echarts/renderers';
import store from "@/store";
import intro from "@/views/Intro";
import {ElMessage} from "element-plus";

echarts.use([GridComponent, TitleComponent, ToolboxComponent, TooltipComponent, LegendComponent,
  LineChart, UniversalTransition, CanvasRenderer]);

export default {
  name: "InstanceList",
  inject: ['reload'],
  data() {
    return {
      instances: []
    }
  },
  mounted() {
    this.store = useStore();
    this.getInstanceList(false);
  },

  methods: {
    handleGoDetails(idx, item) {
      this.$router.push({
        path: `/home/instanceDetails/${item.id}`,
      })
    },
    handleGotoCreatePage(idx, item) {
      this.$router.push({
        path: '/home/imageMarket',
      })
    },
    copySuccess() {
      this.$message({
        type: 'success',
        message: `复制成功`
      });
    },
    copyFail() {
      this.$message({
        type: 'warning',
        message: '复制失败'
      });
    },
    handleRefreshState(instance) {
      this.$httpUtil.urlEncoderPost('/dayu/api/v1/instance/refresh-instance-state', {
        instanceId: instance.id
      }).then(res => {
        let result = res.data;
        if (result) {
          instance.instanceStatus = result.instanceStatus;
          instance.instanceStatusStr = result.instanceStatusStr;
          ElMessage({
            message: '状态刷新成功',
            type: 'success',
            plain: true,
            duration: 500
          })
        } else {
          ElMessage({
            message: '状态刷新失败',
            type: 'error',
            plain: true,
            duration: 500
          })
        }
      }, (res) => {
        console.log(res);
      });
    },
    getInstanceList(isRefresh) {
      this.$httpUtil.get('/dayu/api/v1/instance/my-instances', {}).then(res => {
        if (res) {
          this.instances = res.data;
          if (isRefresh) {
            ElMessage({
              message: '实例列表刷新成功',
              type: 'success',
              plain: true,
              duration: 500
            })
          }
        }
      }, res => {
        console.log(res);
      }).finally(() => {
        //
      });
    },
    refresh() {
      this.reload();
    },
    getInstanceStatusTagColor(instance) {
      let status = instance.instanceStatus;
      if (instanceStatus.RUNNING === status) {
        return 'success'
      } else if (instanceStatus.STOP === status) {
        return 'info'
      } else if (instanceStatus.ERROR === status) {
        return 'danger'
      }
      return ''
    },
    getInstanceInitStatusTagColor(instance) {
      let status = instance.cloudInitStatus;
      if (instanceCloudInitStatus.DONE === status) {
        return 'success'
      } else if (instanceCloudInitStatus.UNKNOWN === status) {
        return 'info'
      } else if (instanceCloudInitStatus.ERROR === status) {
        return 'danger'
      }
      return ''
    },
    getInstanceVolumeStatusTagColor(instance) {
      let status = instance.volumeCheckStatus;
      if (instanceVolumeStatus.NORMAL === status) {
        return 'success'
      } else if (instanceVolumeStatus.UNKNOWN === status) {
        return 'info'
      } else if (instanceVolumeStatus.ERROR === status) {
        return 'danger'
      }
      return ''
    }
  }
}
</script>

<style scoped lang="less">
@import "@/assets/style/common.less";

#container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

#operation-board {
  display: flex;
  justify-content: space-between;
}
</style>
